import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import Seo from '../components/seo';

import Hero from '../components/rows/hero/index';
import Banner from '../images/hero-images/arrhythmia-care-banner.jpg';
import TwoCol from '../components/rows/twocol/index';

import AccordionTitle from '../components/elements/accordion/accordionTitle';
import Accordion from '../components/elements/accordion';
import CtaFluid from '../components/rows/cta/ctaFluid';

import FeaturedDoctors from '../components/elements/doctors/featured';

export default function ArrhythmiaCare({ data }) {
  const addBtn = {
    addBtnName: 'Watch Video',
    addBtnHref: 'https://www.youtube.com/watch?v=QitQzAd3etw',
  };

  return (
    <Layout>
      <Seo
        title="Cardiac"
        description="Together with Southlake Regional Cardiac Care Program, we’ve cared for patients with arrhythmias for decades."
      />
      <Hero title="Arrhythmia Care" banner={Banner} />
      {data.allStrapiCardiac?.edges.map(({ node }) => (
        <React.Fragment key={node}>
          <TwoCol
            title={node.cardiac_two_col?.title}
            description={node.cardiac_two_col?.description}
            image={node.cardiac_two_col.image?.localFile?.publicURL}
            link={node.cardiac_two_col?.button_title}
            href={node.cardiac_two_col?.button_link}
            newTab
            addBtnName={addBtn.addBtnName}
            addBtnHref={addBtn.addBtnHref}
          />
          <div className=" bg-gray-100 mt-40 py-52 flex flex-col medical-bg">
            <AccordionTitle
              title={node.accordion_title?.title}
              subTitle={node.accordion_title?.subtitle}
            >
              <Accordion
                data={node?.cardiac_acc}
                itemButtonClass="accordion__button__custom accordion__button"
              />
            </AccordionTitle>
          </div>
          <div className="py-52">
            <TwoCol
              title={node.cardiac_img_text?.title}
              description={node.cardiac_img_text?.description}
              image={node.cardiac_img_text.image?.localFile?.publicURL}
              link={node.cardiac_img_text?.button_title}
              href={node.cardiac_img_text?.button_link}
              newTab
              reverse
            />
          </div>

          <CtaFluid
            // imgSrc, title, subTitle, btnName, btnLink, bgColor
            imgSrc={node.cta_fluid.img?.localFile.publicURL}
            title={node.cta_fluid?.title}
            subTitle={node.cta_fluid?.sub_title}
            btnName={node.cta_fluid?.btn_name}
            btnLink={node.cta_fluid?.btn_link_to}
            bgColor="#1D3557"
          />
        </React.Fragment>
      ))}
      <FeaturedDoctors
        data={data.allStrapiTeams?.edges}
        subtitle="Ready to support you every step of the way, our team of certified Arrhythmia Health Care Professionals are available to help at our Barrie and Newmarket locations."
        title='Our <span class="text-red-300">Arrhythmia Care</span> Team'
        customClass="max-w-7xl mx-auto my-0 pt-10 pb-20 text-center"
      />
    </Layout>
  );
}

export const query = graphql`
  query Cardiac {
    allStrapiCardiac {
      edges {
        node {
          id # id of the node
          cardiac_acc {
            title
            description
          }
          cta_fluid {
            btn_name
            btn_link_to
            img {
              localFile {
                publicURL
              }
            }
            sub_title
            title
          }
          accordion_title {
            id
            title
            subtitle
          }
          cardiac_two_col {
            title
            description
            image {
              localFile {
                publicURL
              }
            }
            button_title
            button_link
          }
          cardiac_img_text {
            title
            description
            image {
              localFile {
                publicURL
              }
            }
            button_title
            button_link
          }
        }
      }
    }
    allStrapiTeams(
      filter: { featured: { eq: true }, Category: { eq: "Electrophysiology" } }
    ) {
      edges {
        node {
          id
          name
          slug
          picture {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`;
