import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Container } from 'react-grid-system';
import loadable from '@loadable/component';
import { Link } from 'gatsby';
const CardiacBuyButton = loadable(() =>
  import('../../elements/shopify/cardiacBuyButton')
);

const CtaFluid = ({
  imgSrc,
  title,
  subTitle,
  btnName,
  btnLink,
  bgColor,
  isInternalLink,
  shopifyBtn,
}) => {
  const ExternalLink = () => (
    <a
      href={btnLink}
      className="inline-flex p-6 rounded-md duration-300 justify-center 
      border-2   text-white hover:bg-green-500 
      bg-red-500 border-red-500 hover:border-gray-700 
       hover:pointer"
      target="_blank"
      rel="noreferrer"
    >
      {btnName}
    </a>
  );

  const OutSideBtn = () => {
    return (
      <>
        {!!shopifyBtn ? (
          <CardiacBuyButton
            btnEleId={shopifyBtn.btnEleId}
            componentId={shopifyBtn.componentId}
            moneyFormat={shopifyBtn.moneyFormat}
          />
        ) : (
          <ExternalLink />
        )}
      </>
    );
  };

  return (
    <div
      className={`w-full  py-20 overflow-hidden`}
      style={{ backgroundColor: bgColor }}
    >
      <Container>
        <div className="flex justify-center items-center flex-col">
          {imgSrc && (
            <div className="pt-2 pb-12">
              <img src={imgSrc} alt={title} />
            </div>
          )}
          <h3 className="text-center font-bold text-white">
            <ReactMarkdown
              children={title}
              components={{
                p: React.Fragment,
              }}
            />
          </h3>
          {subTitle && (
            <p className=" mx-auto my-0 pt-4 pb-10 text-white">{subTitle}</p>
          )}
          {isInternalLink ? (
            <Link
              to={`/${btnLink}`}
              className="inline-flex p-6 rounded-md duration-300 justify-center 
            border-2   text-white hover:bg-green-500 
            bg-red-500 border-red-500 hover:border-gray-700 
            hover:pointer"
            >
              {btnName}
            </Link>
          ) : (
            <OutSideBtn />
          )}
        </div>
      </Container>
    </div>
  );
};

export default CtaFluid;
