import React from 'react';
import { Link } from 'gatsby';
import { Container } from 'react-grid-system';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import Button from '../button';

export default function Featured({
  data,
  title,
  subtitle,
  isShowDiscoverTeam,
  customClass,
}) {
  const titleClass = customClass
    ? customClass
    : 'specialist-intro text-center md:w-4/12 mx-auto';
  return (
    <Container className="my-52">
      <div className={titleClass}>
        <h2 dangerouslySetInnerHTML={{ __html: title }} />
        <p>{subtitle}</p>
      </div>
      <div
        className={`grid grid-cols-2 md:grid-cols-5 gap-10 md:gap-20 mt-36 content-center`}
      >
        {data.map((ye) => (
          <Link
            className="employee text-center"
            key={ye.node.id}
            to={`/specialists/${ye.node.slug}`}
          >
            <LazyLoadImage
              alt={ye.node.name}
              src={ye.node.picture.localFile.publicURL}
              className="rounded-xl shadow-xl "
              effect="blur"
              wrapperClassName="thumbnail_team_img"
            />
            <h5 className="font-bold mt-5">{ye.node.name}</h5>
          </Link>
        ))}
      </div>
      {isShowDiscoverTeam && (
        <div className="w-full text-center mt-20">
          <Button
            red
            href="/specialists"
            link="Discover our full list of specialists"
          />
        </div>
      )}
    </Container>
  );
}
